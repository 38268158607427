<template>
	<div class="home">
		<meta name="keywords" content="m3dao" />
		<div class="banner">
			<div class="font">
				{{ $t('home.slogan1') }}
				<br />
				{{ $t('home.slogan2') }}
				<br />
				{{ $t('home.slogan3') }}
			</div>
			<div class="banner_content">
				{{ $t('home.font') }}
			</div>
			<div class="button_box">
				<button>
					<a target="_blank" href="https://app.voobank.com/">Apply for Voobank Card</a>
				</button>
				<button><a target="_blank" href="https://app.voobank.com/">Login Now</a></button>
				<button @mouseover="eoverLeftArrow" @mouseleave="eleaveLeftArrow('', false)">
					Download
				</button>
				<div class="down_box" v-show="state.downFlag">
					<div>
						<img src="../assets/home/android.png" alt="Android" />
						<span>Android</span>
					</div>
					<!-- <div>
						<img src="../assets/home/ios.png" alt="ios" />
						<span>ios</span>
					</div> -->
				</div>
			</div>
		</div>
		<div class="item_box core_box">
			<div class="item_box_title core_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.Core') }}
				</span>
			</div>
			<div class="core_box_body">
				<!-- <SwiperCarousel class="swiper" /> -->
				<div class="swiper_pc">
					<div
						v-for="(item, index) in state.iconList"
						:key="index"
						class="swiper_item_pc"
						@mouseover="eoverLeftArrow(index + 1, true)"
						@mouseleave="eleaveLeftArrow(index + 1, true)"
					>
						<img :src="`${state.h5Http}/${item.src}.png`" alt="" />
						<p>{{ item.title }}</p>
						<span>{{ item.content }}</span>
					</div>
				</div>
				<div
					v-for="(item, index) in state.iconList"
					:key="index"
					class="swiper_item_h5"
					@mouseover="eoverLeftArrow(index + 1, true)"
					@mouseleave="eleaveLeftArrow(index + 1, true)"
				>
					<img :src="`${state.h5Http}/${item.src}.png`" alt="" />
					<p>{{ item.title }}</p>
					<span>{{ item.content }}</span>
				</div>
			</div>
		</div>
		<div class="item_box mar_box">
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.Backing') }}
				</span>
			</div>
			<div class="mar">
				<Vue3Marquee style="z-index: 1" :pause-on-hover="true" duration="40">
					<div class="border-box" v-for="(word, index) in state.helloArray" :key="index">
						<img :src="`${state.http}/${word}.png`" alt="" />
					</div>
				</Vue3Marquee>
				<Vue3Marquee style="z-index: 1" :pause-on-hover="true" direction="reverse" duration="40">
					<div class="border-box" v-for="(word, index) in state.helloArray2" :key="index">
						<img :src="`${state.http}/${word}.png`" alt="" />
					</div>
				</Vue3Marquee>
			</div>
		</div>
		<div class="item_box cta_box">
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.CTA') }}
				</span>
			</div>
			<div class="cta_box_body">
				<img
					class="card"
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmYCZ913Tignmx1iyBSPc5BWiKBC3kuwnT1Zg8GBcAxc7v"
					alt=""
				/>
				<div class="button_box">
					<button>
						<a target="_blank" href="https://app.voobank.com/">Apply for Voobank Card</a>
					</button>
					<button @mouseover="eoverLeftArrow" @mouseleave="eleaveLeftArrow('', false)">
						Download
					</button>
					<button>
						<a target="_blank" href="https://app.voobank.com/">Open Account</a>
					</button>
					<button @click="scrollToAnchor('aaa')">Learn More</button>
					<div class="down_box2" v-show="state.downFlag">
						<div>
							<img src="../assets/home/android.png" alt="Android" />
							<span>Android</span>
						</div>
						<!-- <div>
							<img src="../assets/home/ios.png" alt="ios" />
							<span>ios</span>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="item_box about_box">
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('home.about') }}
				</span>
			</div>
			<div class="about_box_font">
				MarsVerse, one of the core products of M3 DAO, is an innovative metaverse ecosystem
				dedicated to the vision of interstellar colonization. It leverages Avatar technology to
				foster a global community system and create a dynamic commercial landscape. By integrating
				advanced Web3 technologies, MarsVerse aims to unite users worldwide, enabling them to
				participate in a virtual environment that simulates the challenges and opportunities of
				colonizing Mars.
			</div>
			<div class="item_box_body about_box_body">
				<div class="about_item">
					<div class="about_item_title">Mission</div>
					<div class="about_item_font">
						Strive for the ultimate freedom of humanity, provide everyone with the most promising
						Layer 2 public chain, and help everyone achieve the Mars dream!
					</div>
				</div>
				<div class="about_item">
					<div class="about_item_title">Values</div>
					<div class="about_item_font">
						Innovation is value. MarsChain is centered on innovation, rooted in Layer 2, serving
						Web3, and serving all of humanity.
					</div>
				</div>
				<div class="about_item">
					<div class="about_item_title">History</div>
					<div class="about_item_font">
						MarsVerse, founded by a top-tier team of Web3 experts with strong blockchain and finance
						backgrounds, is dedicated to delivering innovative technology and experiences in 2024.
						Key milestones include launching an immersive metaverse platform, avatar products, and
						crypto credit cards that connect traditional finance with the metaverse and more. Along
						with business growth, the community has also expanded to over 100,000 real users across
						more than 100 countries and regions worldwide.
					</div>
				</div>
				<div class="about_item">
					<div class="about_item_title">Vision</div>
					<div class="about_item_font">
						Establish a community of Mars colonization volunteers through Avatar, collaboratively
						build MarsChain, create the strongest ecosystem, and ultimately achieve Mars
						colonization!
					</div>
				</div>
				<div class="about_item">
					<div class="about_item_title">Contribution</div>
					<div class="about_item_font">
						SMarsVerse enhances the Web3 and fintech landscapes by creating an immersive metaverse
						where users engage in colonization challenges through digital avatars. With the Avatar
						Alliance, it fosters collaboration and innovation. Additionally, MarsVerse offers crypto
						credit cards that bridge traditional finance with the metaverse, enabling seamless
						transactions between fiat and cryptocurrencies. This integration redefines digital
						engagement and sets new standards for inclusivity in the evolving Web3 economy.
					</div>
				</div>
			</div>
		</div>
		<div class="item_box faq_box" ref="aaa">
			<div class="item_box_title">
				<img
					src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
					alt=""
				/>
				<span>
					{{ $t('support.icon_title') }}
				</span>
			</div>
			<div class="item_box_body">
				<div class="item_box_item" v-for="(item, index) in state.arrList" :key="index">
					<div class="title" @click="change(item)">
						<span>{{ item.title }}</span>
						<span class="color" v-show="!item.active">+</span>
						<span class="color" v-show="item.active">-</span>
					</div>
					<div class="content" v-show="item.active">
						<span>
							{{ item.content }}
						</span>
						<a href="https://app.voobank.com/#/mainPage" target="_blank">https://app.voobank.com</a>
					</div>
				</div>
			</div>
		</div>
		<img
			src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmRpSaNrF9hjBnYgKm5iwMSKsCge9RLjzEMuZVgvQnPDRX'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmRpSaNrF9hjBnYgKm5iwMSKsCge9RLjzEMuZVgvQnPDRX"
			alt=""
		/>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import SwiperCarousel from '../components/SwiperCarousel.vue';
const aaa = ref(null);
const active = ref('aaa');
const scrollToAnchor = (ref) => {
	active.value = ref;
	if (ref == 'aaa') {
		aaa.value.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
	}
};

const { tm } = useI18n();
const router = useRouter();

const state = reactive({
	http: 'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmQy5YWELAhqcFEfpvoGQZaYoowaxZQucbcVGPPjgsVfk4',
	arrList: [
		{
			title: '1. What is the Voobank Digital Asset Card and how does it work?',
			content:
				'The Voobank Digital Asset Card is a digital asset card that allows you to manage and spend cryptocurrencies easily. It links directly to your cryptocurrency wallet, enabling seamless transactions at various merchants. To learn more, please visit: ',
			active: false,
		},
		{
			title: '2. What types of cards are offered by Voobank?',
			content:
				'Voobank offers both virtual cards for online transactions and physical cards for in-store purchases. Each type is designed for different spending needs. To learn more, please visit: ',
			active: false,
		},
		{
			title: '3. How do I register for a Voobank account?',
			content:
				"To register, go to the Voobank website, click 'Sign Up,' and fill out the registration form with your personal details. You'll need to verify your email address to complete the process. To learn more, please visit: ",
			active: false,
		},
		{
			title: '4. What documents are needed for identity verification (KYC)?',
			content:
				"For KYC verification, you'll need a government-issued ID (like a passport or driver's license) and a document proving your address, such as a utility bill or bank statement. To learn more, please visit: ",
			active: false,
		},
		{
			title: '5. How can I apply for the Voobank Digital Asset Card?',
			content:
				'You can apply for the Digital Asset Card by logging into your Voobank account and navigating to the Digital Asset Card section. Follow the on-screen instructions to complete your application. To learn more, please visit: ',
			active: false,
		},
		{
			title: '6. What is the process for recharging my digital asset card?',
			content:
				"To recharge your card, log into your account, select the 'Recharge' option, choose the cryptocurrency you wish to use, enter the amount, and follow the prompts to complete the transaction. To learn more, please visit: ",
			active: false,
		},
		{
			title: '7. Which cryptocurrencies can I use to recharge my card?',
			content:
				'You can recharge your Voobank Digital Asset Card with major cryptocurrencies like Bitcoin, Ethereum, and other supported altcoins. Check the Voobank website for a complete list of accepted currencies. To learn more, please visit: ',
			active: false,
		},
		{
			title: '8. How do I check my transaction history on the Voobank Digital Asset Card?',
			content:
				"To check your transaction history, log into your Voobank account and navigate to the 'Transactions' section in your Digital Asset Card dashboard. This will provide a detailed history of all your transactions. To learn more, please visit: ",
			active: false,
		},
		{
			title: '9. What should I do if I encounter issues during the application process?',
			content:
				'If you face issues, consult the FAQ section on the Voobank website for common problems. If your issue persists, contact customer support for assistance. To learn more, please visit: ',
			active: false,
		},
		{
			title: '10. How long does the identity verification process take?',
			content:
				'The identity verification process typically takes between 1 to 5 minutes, but it may take longer during peak times due to high demand. To learn more, please visit: ',
			active: false,
		},
	],
	helloArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
	helloArray2: [11, 12, 13, 14, 15, 16, 17, 18, 19],
	downFlag: false,
	h5Http:
		'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmPJ3BEKAhXB4tafQwHVBVjHDBfygFzny8MEGDcH8w8sWT',
	iconList: [
		{
			href: '',
			src: '1',
			title: 'Worry-free Global Payments',
			content: 'Supports Visa and MasterCard, with payments and withdrawals in over 150 countries.',
		},
		{
			href: '',
			src: '2',
			title: 'Real-time Conversion',
			content:
				'Instant conversion of cryptocurrencies to fiat currency, usable anytime and anywhere.',
		},
		{
			href: '',
			src: '3',
			title: 'Digital Banking Services',
			content: 'One-stop management of your digital assets and traditional bank accounts.',
		},
		{
			href: '',
			src: '4',
			title: 'Advanced Security Protection',
			content:
				'Multi-factor authentication (MFA), 256-bit encryption technology, providing comprehensive protection for your assets.',
		},
	],
});

const change = (item) => {
	item.active = !item.active;
};
const eoverLeftArrow = (item, type) => {
	if (!type) {
		state.downFlag = true;
	} else {
		state.iconList.map((items) => {
			if (items.src == item) {
				items.src = item + '_hover';
			}
		});
	}
};
const eleaveLeftArrow = (item, type) => {
	if (!type) {
		state.downFlag = false;
	} else {
		state.iconList.map((items) => {
			if (items.src == item + '_hover') {
				items.src = item;
			}
		});
	}
};
</script>

<style scoped lang="scss">
/*
	进入和离开动画可以使用不同
	持续时间和速度曲线。
	*/
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translatey(20px);
	opacity: 0;
}
/* 当前屏幕大于768px 应用媒体查询里面的样式 */
@media screen and (min-width: 768px) {
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		// padding-bottom: 214px;
		.banner {
			width: 100%;
			height: 930px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmZ4LjzzgKi44pyvwjhUfMAaJRe9z2WyBpEMYQvu2xnGa5');
			background-size: 1920px auto;
			background-position: 60px -310px;
			padding: 176px 360px 0;
			.font {
				margin: 0 0 14px;
				font-size: 50px;
				font-weight: 500;
				line-height: 58px;
				color: #ca8232;
				// text-shadow: 10 0 20px #ffffff, 0 10 20px #ffffff, -10 0 20px #ffffff, 0 -10 20px #ffffff; //设置发光效果
			}
			.banner_content {
				width: 593px;
				font-size: 22px;
				font-weight: 400;
				line-height: 30px;
			}
			.button_box {
				width: 593px;
				display: flex;
				justify-content: space-between;
				margin-top: 77px;
				position: relative;
				button {
					height: 44px;
					color: #ffffff;
					border: 1px solid #986226;
					font-size: 16px;
					font-weight: 400;
					background: none;
					padding: 10px 30px;
					border-radius: 6px;
				}
				button:hover {
					background: #986226;
				}
				.down_box {
					position: absolute;
					right: -6px;
					top: 64px;
					z-index: 2;
					// width: 284px;
					width: 142px;
					height: 175px;
					border: 1px solid #f0a639;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					background: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0.23),
						rgba(153, 153, 153, 0.23)
					);
					div {
						width: 108px;
						text-align: center;
						font-size: 16px;
					}
					img {
						width: 108px;
						height: 108px;
						margin-bottom: 7px;
					}
				}
			}
		}
		.item_box {
			width: 100%;
			padding: 0 360px 240px;
			.item_box_title {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 56px;
				line-height: 70px;
				margin-top: 150px;
				margin-bottom: 76px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}

			.item_box_body {
				// display: flex;
				width: 100%;
				// height: 100vh;
				.item_box_item {
					width: 100%;
					min-height: 61px;
					background: #0d0d0d;
					padding: 17px 20px 0;
					border-radius: 12px;
					margin-bottom: 16px;
					cursor: pointer;
					.title {
						width: 100%;
						font-size: 18px;
						font-weight: 400;
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						.color {
							color: #986226;
							font-size: 24px;
						}
					}
					.content {
						margin-top: 10px;
						padding: 0 8px 17px;
						height: auto;
						color: #ababab;
					}
				}
			}
		}
		.core_box {
			padding: 0 0;
			overflow: hidden;
			.item_box_title {
				padding: 0 360px;
				margin: 0;
			}
			.core_box_title {
				margin-bottom: 40px;
			}
			.core_box_body {
				padding: 0 180px;
				padding-top: 100px;
				position: relative !important;
				.swiper_pc {
					width: 100%;
					height: 320px;
					display: flex;
					justify-content: space-between;
					.swiper_item_pc {
						width: 370px !important;
						height: 320px;
						border: 1px solid #986226;
						border-radius: 10px;
						// margin-right: 27px !important;
						padding: 42px 0 22px 16px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						img {
							width: 60px;
							height: 60px;
							margin-bottom: 20px;
						}
						p {
							font-size: 32px;
							font-weight: 300;
							line-height: 32px;
						}
						span {
							margin-top: 10px;
							font-size: 18px;
							font-weight: 400;
							line-height: 18px;
							color: #cccccc;
						}
					}
					.swiper_item_pc:hover {
						background: #ca8232;
					}
				}
				.swiper_item_h5 {
					display: none;
				}
			}
		}
		.mar_box {
			padding: 0 0 300px;
			.mar {
				width: 100%;
				height: 224px;
				.border-box {
					margin-bottom: 40px;
					padding: 20px 30px;
					margin-right: 20px;
					font-size: 28px;
					font-weight: 500;
					cursor: pointer;
					border-top: 0px;
					border-bottom: 0px;
					background: #ebebeb;
					border-radius: 20px;
					img {
						width: 280px;
						height: 120px;
					}
				}
				// .border-box:hover {
				// color: #000000;
				// border: 1px solid rgba(255, 255, 255, 0.6);
				// background: linear-gradient(to right, #2a7bcc, #fdaa33);
				// }
			}
		}
		.cta_box {
			padding: 0 0;
			.item_box_title {
				margin-bottom: 0;
			}
			.cta_box_body {
				width: 100%;
				height: 1354px;
				background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmSkw7a5ZeTPYnPvWXdUvpSAjjwpaAzqSue8NDp17nTofX');
				background-size: 1920px 1354px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.card {
					width: 734px;
					height: 419px;
					margin: 113px auto 116px;
				}
				.button_box {
					width: 802px;
					height: 44px;
					display: flex;
					justify-content: space-between;
					position: relative;
					button {
						height: 44px;
						color: #ffffff;
						border: 1px solid #986226;
						font-size: 16px;
						font-weight: 400;
						background: none;
						padding: 10px 30px;
						border-radius: 6px;
						background: #252525;
					}
					button:hover {
						background: #986226;
					}
					.down_box2 {
						position: absolute;
						left: 270px;
						top: 64px;
						z-index: 2;
						// width: 284px;
						width: 142px;
						height: 175px;
						border: 1px solid #f0a639;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-around;
						background: linear-gradient(
							to bottom,
							rgba(255, 255, 255, 0.23),
							rgba(153, 153, 153, 0.23)
						);
						div {
							width: 108px;
							text-align: center;
							font-size: 16px;
						}
						img {
							width: 108px;
							height: 108px;
							margin-bottom: 7px;
						}
					}
				}
			}
		}
		.about_box {
			padding-bottom: 0px;
			.item_box_title {
				margin: -300px 0 80px;
			}
			.about_box_font {
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 80px;
			}
			.about_box_body {
				display: flex;
				justify-content: space-between;
				flex-flow: wrap;
				.about_item {
					margin-bottom: 28px;
					width: 585px;
					height: 521px;
					border: 1px solid #878787;
					padding: 20px;
					border-radius: 10px;
					.about_item_title {
						font-size: 49px;
						font-weight: 600;
						margin-bottom: 30px;
					}
					.about_item_font {
						font-size: 24px;
						font-weight: 400;
					}
				}
				.about_item:nth-child(1) {
					background: linear-gradient(to bottom, #000000, #986226);
				}
				.about_item:nth-child(2) {
					background: linear-gradient(to bottom, #000000, #41037f);
				}
				.about_item:nth-child(3) {
					background: linear-gradient(to bottom, #000000, #0db3a9);
				}
				.about_item:nth-child(4) {
					background: linear-gradient(to bottom, #000000, #143ed4);
				}
				.about_item:last-child {
					width: 100%;
					height: 330px;
					background: linear-gradient(to bottom, #000000, #c8955c);
				}
			}
		}
		.faq_box {
			padding-top: 150px;
			.item_box_title {
				margin-top: 0;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.home {
		width: 100%;
		color: #ffffff;
		background: #000;
		// padding-bottom: 214px;
		.banner {
			width: 100%;
			height: auto;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmT3TqMCdztpF6yh2c2D4ADSgTqRz7x9ySjceRvRQRvxTw')
				no-repeat;
			background-size: 375px 450px;
			// background-position: 0;
			padding: 0;
			text-align: center;
			.font {
				width: 317px;
				margin: 0 auto 9px;
				padding-top: 347px;
				font-size: 24px;
				font-weight: 500;
				line-height: 29px;
				color: #ca8232;
				// text-shadow: 10 0 20px #ffffff, 0 10 20px #ffffff, -10 0 20px #ffffff, 0 -10 20px #ffffff; //设置发光效果
			}
			.banner_content {
				margin: 0 auto 37px;
				width: 295px;
				font-size: 22px;
				font-weight: 400;
				line-height: 30px;
			}
			.button_box {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 77px;
				position: relative;
				button {
					width: 227px;
					height: 44px;
					color: #ffffff;
					border: 1px solid #986226;
					font-size: 16px;
					font-weight: 400;
					background: none;
					padding: 10px 20px;
					border-radius: 6px;
					margin-bottom: 29px;
				}
				button:hover {
					background: #986226;
				}
				.down_box {
					position: absolute;
					// right: -6px;
					bottom: -170px;
					z-index: 2;
					// width: 284px;
					width: 174px;
					height: 175px;
					border: 1px solid #f0a639;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
					background: linear-gradient(
						to bottom,
						rgba(255, 255, 255, 0.23),
						rgba(153, 153, 153, 0.23)
					);
					div {
						width: 108px;
						text-align: center;
						font-size: 16px;
					}
					img {
						width: 108px;
						height: 108px;
						margin-bottom: 7px;
					}
				}
			}
		}
		.item_box {
			width: 100%;
			padding: 0 26px 240px;
			.item_box_title {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				font-size: 28px;
				line-height: 35px;
				margin-top: 82px;
				margin-bottom: 76px;
				text-align: center;
				img {
					width: 12px;
					height: 12px;
					margin-right: 6px;
				}
			}

			.item_box_body {
				// display: flex;
				width: 100%;
				// height: 100vh;
				.item_box_item {
					width: 100%;
					min-height: 61px;
					background: #0d0d0d;
					padding: 10px 20px;
					border-radius: 12px;
					margin-bottom: 16px;
					cursor: pointer;
					.title {
						width: 100%;
						font-size: 18px;
						font-weight: 400;
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						.color {
							color: #986226;
							font-size: 24px;
						}
					}
					.content {
						margin-top: 10px;
						padding: 0 8px 17px;
						height: auto;
						color: #ababab;
					}
				}
			}
		}
		.core_box {
			padding: 0 0;
			overflow: hidden;
			.item_box_title {
				padding: 0 26px;
				margin: 0;
			}
			.core_box_title {
				margin-bottom: 40px;
			}
			.core_box_body {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0;
				margin: 0 auto;
				.swiper {
					display: none;
				}
				.swiper_item_h5 {
					width: 300px !important;
					height: 256px;
					border: 1px solid #986226;
					border-radius: 10px;
					padding: 30px 10px 0 14px;
					margin-bottom: 24px;
					img {
						width: 54px;
						height: 54px;
						margin-bottom: 16px;
					}
					p {
						font-size: 28px;
						font-weight: 300;
						line-height: 28px;
						margin-bottom: 6px;
					}
					span {
						margin-top: 4px;
						font-size: 12px;
						font-weight: 400;
						line-height: 12px;
						color: #cccccc;
					}
				}
				.swiper_item_h5:hover {
					background: #ca8232;
				}
				.swiper_item_pc {
					display: none;
				}
			}
		}
		.mar_box {
			padding: 0 0 100px;
			.mar {
				width: 100%;
				// height: 224px;
				.border-box {
					width: 148px;
					height: 64px;
					margin-bottom: 20px;
					// padding: 20px 30px;
					margin-right: 24px;
					font-size: 28px;
					font-weight: 600;
					cursor: pointer;
					border-top: 0px;
					border-bottom: 0px;
					background: #ebebeb;
					border-radius: 20px;
					img {
						width: 148px;
						height: 64px;
					}
				}
				// .border-box:hover {
				// color: #000000;
				// border: 1px solid rgba(255, 255, 255, 0.6);
				// background: linear-gradient(to right, #2a7bcc, #fdaa33);
				// }
			}
		}
		.cta_box {
			padding: 0 0;
			.item_box_title {
				margin-bottom: 0;
			}
			.cta_box_body {
				width: 100%;
				height: 520px;
				background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmbJ3ZFGzXJySUJqUAAhwp1ZYSVLKL7ZaqkDVpoQrpr5c3')
					no-repeat;
				background-size: 375px 413px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.card {
					width: 218px;
					height: 124px;
					margin: 100px auto 60px;
				}
				.button_box {
					// width: 802px;
					height: 44px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: relative;
					button {
						width: 230px;
						height: 44px;
						color: #ffffff;
						border: 1px solid #986226;
						font-size: 16px;
						font-weight: 400;
						background: none;
						padding: 10px 20px;
						border-radius: 6px;
						background: #252525;
						margin-bottom: 20px;
					}
					button:hover {
						background: #986226;
					}
					.down_box2 {
						position: absolute;
						left: 44px;
						top: 234px;
						z-index: 2;
						// width: 284px;
						width: 142px;
						height: 175px;
						border: 1px solid #f0a639;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-around;
						background: linear-gradient(
							to bottom,
							rgba(255, 255, 255, 0.23),
							rgba(153, 153, 153, 0.23)
						);
						div {
							width: 108px;
							text-align: center;
							font-size: 16px;
						}
						img {
							width: 108px;
							height: 108px;
							margin-bottom: 7px;
						}
					}
				}
			}
		}
		.about_box {
			padding-bottom: 0px;
			.item_box_title {
				// margin: -300px 0 80px;
				margin-top: 200px;
			}
			.about_box_font {
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 20px;
				text-align: center;
			}
			.about_box_body {
				display: flex;
				justify-content: space-between;
				flex-flow: wrap;
				.about_item {
					margin-bottom: 28px;
					// width: 585px;
					min-height: 260px;
					border: 1px solid #878787;
					padding: 20px;
					border-radius: 10px;
					.about_item_title {
						font-size: 40px;
						font-weight: 600;
						margin-bottom: 20px;
					}
					.about_item_font {
						font-size: 20px;
						font-weight: 400;
					}
				}
				.about_item:nth-child(1) {
					background: linear-gradient(to bottom, #000000, #986226);
				}
				.about_item:nth-child(2) {
					background: linear-gradient(to bottom, #000000, #41037f);
				}
				.about_item:nth-child(3) {
					background: linear-gradient(to bottom, #000000, #0db3a9);
				}
				.about_item:nth-child(4) {
					background: linear-gradient(to bottom, #000000, #143ed4);
				}
				.about_item:last-child {
					width: 100%;
					// height: 330px;
					background: linear-gradient(to bottom, #000000, #c8955c);
				}
			}
		}
		.faq_box {
			padding: 150px 26px 120px;
			font-size: 12px;
			.item_box_title {
				margin-top: 0;
			}
			.item_box_item {
				font-size: 14px;
				color: #f5f5f8;
				.content {
					color: #ababab;
				}
			}
		}
	}
}
</style>
